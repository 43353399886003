const TWEEN = require('@tweenjs/tween.js');

const minDesktopWidth = 1200;
const itemCount = 4;

window.document.addEventListener('DOMContentLoaded', () => {
  const logo = document.querySelectorAll('.logo-link');
  const contents = document.querySelector('.contents');
  const page = document.querySelector('.page');
  const slider = document.querySelector('.contents-slider');
  const items = [...document.querySelectorAll('.contents-section')];
  const menuItems = document.querySelectorAll('.mainMenu-link');
  const pageWidth = page.offsetWidth;
  const pageHeight = page.offsetHeight;
  const toScale = document.querySelectorAll('.sider-mainMenu, .page-contents, .page-footer');
  const siderLogo = document.querySelector('.sider-logo');

  let current = 0;
  let busy = false;

  const resizeContents = (i) => {
    slider.style.height = items[i].querySelector('.section-contents').offsetHeight + 50 + 'px'
  }

  const resizePage = () => {
    let scale = 1;
    if (window.innerWidth > minDesktopWidth) {
      scale = Math.min(window.innerWidth / pageWidth, window.innerHeight / pageHeight);
    }

    toScale.forEach(item => item.style.transform = `scale(${scale})`);
    siderLogo.style.fontSize = `${scale * 16}px`;
  }

  const windowResize = () => {
    resizePage();
    resizeContents(current);
  }

  window.addEventListener("resize", windowResize);
  windowResize();

  const setPage = (i) => {
    if (i === current || busy) return false;
    busy = true;

    page.setAttribute('data-section', menuItems[i].innerHTML);

    const attribute = window.innerWidth > minDesktopWidth ? 'top' : 'left';

    const position = {p: i > current ? 0 : -100, scroll: contents.scrollTop}
    const tween = new TWEEN.Tween(position)
      .to({p: i > current ? -100 : 0, scroll: 0}, 300)
      .onComplete(() => {
        if (i > current) {
          slider.append(items[current]);
        }
        slider.style[attribute] = `0%`;
        current = i;
        busy = false;
      })
      .onUpdate(() => {
        slider.style[attribute] = `${position.p}%`;
      });

    if (i > current) {
      slider.insertBefore(items[i], items[current].nextSibling);
    } else {
      slider.insertBefore(items[i], items[current]);
    }

    tween.start();
    resizeContents(i);
  };

  Array.from(logo).map(item => item.addEventListener('click', (event) => {
    event.stopPropagation();
    event.preventDefault();
    page.setAttribute('data-section', 'About');
    setPage(0);
  }));

  menuItems.forEach((item) => {
    item.addEventListener('click', function (event) {
      const i = Array.from(item.parentElement.parentElement.children).findIndex((el) => el.querySelector('a') === item)
      event.stopPropagation();
      event.preventDefault();
      setPage(i);
    });
  })

  window.addEventListener("wheel", (e) => {
    if (window.innerWidth > minDesktopWidth) {
      if (e.wheelDeltaY < 0 && current < itemCount - 1) {
        setPage(current + 1);
      } else if (e.wheelDeltaY > 0 && current > 0) {
        setPage(current - 1);
      }
    }
  });

  animate()

  function animate() {
    requestAnimationFrame(animate)
    TWEEN.update()
  }
});
